import {
	authenticate,
	IAuthenticateRequest
} from '@anghami/neogateway/dist/endpoints/authentication';
import { gateway } from './neogateway';

type IDoAuthUser = {
	email?: string;
	token?: string;
	userID?: string;
	accessToken?: string;
	username?: string;
	password?: string;
	userid?: string;
	code?: string;
	identitytoken?: string;
	authorizationcode?: string;
};

export const doAuthentication = async (type: IAuthenticateRequest['m'], user: IDoAuthUser) => {
	const body: IAuthenticateRequest = {
		m: type
	};

	switch (type) {
		case 'gop':
			body.u = user.email;
			body.p = user.token;
			break;

		case 'fb':
			body.fid = user.userID;
			body.ftk = user.accessToken;
			break;

		case 'an':
			body.u = user.email || user.username;
			body.p = encodeURIComponent(user.password);
			break;

		case 'lc':
			body.u = user.userid;
			body.p = user.code;
			body.tv = true;
			body._magic = true;
			break;

		case 'ap':
			body.identitytoken = user.identitytoken;
			body.authorizationcode = user.authorizationcode;
			break;

		default:
			break;
	}

	return gateway.callEndpoint(authenticate, body);
};
